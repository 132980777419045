import {
  CalendarIcon,
  ChevronRightIcon,
  InformationCircleIcon,
  LocationMarkerIcon,
  UsersIcon,
} from '@heroicons/react/solid';
import { Link } from 'gatsby';
import React from 'react';

import { useJobs } from '../../../hooks/useJobs';

type JobType = {
  id: number;
  title: string;
  educationalLevel: {
    id: number;
    text: string;
  };
  contractType: {
    id: number;
    text: string;
  };
  jobType: {
    id: number;
    text: string;
  };
  fieldOfExperties: {
    id: number;
    text: string;
  };
  location: {
    id: number;
    text: string;
  };
  closeDate: {
    isPermanent: boolean;
    date: string;
  };
  responsibilities: string[];
  competencies: {
    required: string[];
    preferred: string[];
  };
  languageSkills: {
    required: string[];
    preferred: [];
  };
  languageOfApplication: string;
  salary: {
    isProvided: boolean;
    minimum: string;
    maximum: string;
  };
  benefits: {
    bonus: string[];
    travel: string[];
    assets: string[];
    training: string[];
    wellbeing: string[];
    catering: string[];
  };
};

const JobList = () => {
  const { getJobs } = useJobs();
  const jobs = getJobs();

  if (jobs.length === 0) {
    return (
      <div className="mx-auto sm:px-6 lg:max-w-7xl">
        <div className="bg-blue-50 border-l-4 border-blue-400 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-blue-700">There are no open jobs currently. Check back soon!</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {jobs.map((job) => (
          <li key={job.id}>
            <Link to={`/job-description#${job.id}`} className="block hover:bg-gray-50">
              <div className="px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <p className="text-sm font-medium text-indigo-600 truncate">{job.title}</p>
                  <div className="ml-2 flex-shrink-0 flex">
                    <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                      {job.contractType.text}
                    </p>
                  </div>
                </div>
                <div className="mt-2 sm:flex sm:justify-between">
                  <div className="sm:flex">
                    <p className="flex items-center text-sm text-gray-500">
                      <UsersIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                      {job.fieldOfExperties.text}
                    </p>
                    <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                      <LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                      {job.location.text}
                    </p>
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                    <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <p>
                      {job.closeDate.isPermanent && <>Permanently open</>}
                      {!job.closeDate.isPermanent && <>Closing on {job.closeDate.date}</>}
                    </p>
                    <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export { JobList };
