import React from 'react';

import { JobFilter } from '../components/PageContent/JobFilter';
import { JobList } from '../components/PageContent/JobList';
import { PageHeader } from '../components/PageHeader';

const Page = () => (
  <>
    <PageHeader title="Open Positions" subtitle={false} actions={false} />
    <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
      <section aria-labelledby="applicant-information-title" className="lg:col-start-1 lg:col-span-3">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
              Find the job you want through confidence
            </h2>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <JobList />
          </div>
        </div>
      </section>
      {
        /*
      <section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1">
        <div className="bg-white shadow sm:rounded-lg">
          <JobFilter />
        </div>
      </section>
      */
      }
    </div>
  </>
);

export default Page;
